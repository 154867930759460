.footer {
    background-color: #222;
    color: #fff;
    padding: None;
    text-align: center;
  }
  
  .footer-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  