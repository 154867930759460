/* src/styles/App.css */
body {
  margin: 0;
  font-family: 'Arial', sans-serif;
  background-color: #1a1a1a;
  color: #fff;
}

.header {
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar {
  margin-top: 80px;
  position: fixed;
  background-color: black;
  height: 80px;
  display: flex;
  align-items: center;
  width: 100%;
  z-index: 900;
}

.navbar-logo img {
  padding-left: 50px;
  height: 70px;
  width: auto;
}

.navbar-links {
  list-style-type: none;
  padding: 40px;
  margin-left: auto;
  display: flex;
}

.navbar-links li {
  margin: 0 15px;
}

.navbar-links li a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-links li a:hover {
  color: #f00;
}

section {
  padding: 40px;
  text-align: center;
}

.slider{
  margin-top: 80px;
}

.slider-image {
  width: 100%; /* Full width */
  height: 500px; /* Adjust height */
  object-fit: cover; /* Maintain aspect ratio */
}

.services{
  margin-top: -55px;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.service-card {
  background-color: #333;
  border-radius: 8px;
  padding: 20px;
  margin: 15px;
  width: 250px; /* Set a fixed width for cards */
  transition: transform 0.3s;
}

.service-card:hover {
  transform: scale(1.05); /* Slightly scale up on hover */
}

.contact-section {
  display: flex;
  flex-direction: row;
  gap: 20px;
  max-width: 80%;
  margin: auto;
}

.contact-form {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  margin: auto;
}

.contact-form label {
  margin: 10px 0 5px;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  padding: 10px;
  margin-bottom: 15px;
}

.contact-form button {
  padding: 10px;
  background-color: #f00; /* Change to your preferred color */
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #c00; /* Darker shade for hover */
}

/* Hide the WhatsApp number input initially */
#whatsappNumber {
  display: none;
}



/* src/styles/App.css */
.logo img {
  height: 50px; /* Adjust height based on your logo */
  width: auto;  /* Maintain aspect ratio */
}


/* Responsive styling for smaller screens */
.menu-icon {
  display: none;
}

@media (max-width: 768px) {
  .navbar-links {
    display: none;
    position: absolute;
    top: 80px;
    right: 0;
    background-color: black;
    width: 100%;
    flex-direction: column;
    padding: 20px 0;
    border-top: 1px solid #333;
  }

  .navbar-links.active {
    display: flex;
  }

  .menu-icon {
    margin-left: auto;
    display: block;
    background: none;
    border: none;
    color: #fff;
    font-size: 1.5rem;
    padding-right: 20px;
    cursor: pointer;
  }

  .navbar-links li {
    /* margin: 10px 0; */
    margin-top: 20;
    text-align: center;
    padding: 10px;
  }

  .contact-section {
    flex-direction: row;
  }
}

.contact-form {
  flex: 1;
  max-width: 600px;
}

.contact-details {
  flex: 1;
  padding: 20px;
  border-left: 2px solid #ddd;
}
